<template>
  <div  class="manager-container">
    <div class="query-item button-bar">
      <el-button type="danger" round @click="handleClearCacheClick()">
        Clear MPA Product Cache
      </el-button>
      <!-- <el-button type="danger" round @click="update()">
        updata all
      </el-button> -->
    </div>
    <div class="table-block">
      <el-table :data="mpaFuelTypeList"
      :cell-style="CELL_STYLE"
      :header-cell-style="HEADER_CELL_STYLE"
      height="100%"
      >
        <el-table-column label="Description" min-width="140">
          <template v-slot="scope">
            <div>
            {{scope.row.fuelTypeDescription ?? '-'}}
          </div>
          </template>
        </el-table-column>
        <el-table-column label="Code">
          <template v-slot="scope">
            <div>
            {{scope.row.fuelTypeCode??'-'}}
          </div>
          </template>
        </el-table-column>
        <el-table-column label="Bunker Type">
          <template v-slot="scope">
            <div>
            {{scope.row.bunkerType??'-'}}
          </div>
          </template>
        </el-table-column>
        <el-table-column label="Oil Type">
          <template v-slot="scope">
              <el-select
                filterable
                style="width: 100%"
                v-model="scope.row.oilType"
                @change="handleEdit($event,scope.row)"
              >
                <el-option
                  v-for="item in OIL_TYPE_OPTIONS"
                  :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
          </template>
        </el-table-column>

        <el-table-column label="Updated At" width="200px">
          <template v-slot="scope">
            <p>{{scope.row?.updatedAt ? $moment(scope.row.updatedAt).format('YYYY-MM-DD HH:mm:ss') : "-"}}</p>
          </template>
        </el-table-column>
      </el-table>
    </div>

  </div>
</template>
<script>
import { mapState } from 'vuex'
import { CELL_STYLE, HEADER_CELL_STYLE, OIL_TYPE_OPTIONS } from '@/constants.js'
export default {
  name: 'FuelTypeCodeList',
  data () {
    return {
      HEADER_CELL_STYLE,
      CELL_STYLE,
      OIL_TYPE_OPTIONS,
      backupList: []
    }
  },
  computed: {
    ...mapState(['mpaFuelTypeList']),
    fuelTypes: function () {
      return this.mpaFuelTypeList
    }
  },
  created () {
    this.backupList = this.$_.cloneDeep(this.mpaFuelTypeList)

  },
  methods: {
    async update(){
      const map = {
        'ULS-HVO':'BIODIESEL',
        'VLS-HVO':'BIODIESEL',
        'ULS-HVO (DMB)':'BIODIESEL',
        'VLS-HVO (DMB)':'BIODIESEL',
        'HVO (DMB)':'BIODIESEL',
        'Bio-ULSMGO':'BIODIESEL',
        'Bio-VLSMGO':'BIODIESEL',
        'Bio-MGO':'BIODIESEL',
        'Bio-VLSMDO (DFB)':'BIODIESEL',
        'Bio-MDO (DFB)':'BIODIESEL',
        'Bio-ULSFO20':'BIOFUEL',
        'Bio-ULSFO80':'BIOFUEL',
        'Bio-ULSFO180':'BIOFUEL',
        'Bio-ULSFO380':'BIOFUEL',
        'Bio-ULSFO500':'BIOFUEL',
        'Bio-VLSFO20':'BIOFUEL',
        'Bio-VLSFO80':'BIOFUEL',
        'Bio-VLSFO180':'BIOFUEL',
        'Bio-VLSFO380':'BIOFUEL',
        'Bio-VLSFO500':'BIOFUEL',
        'Bio-HSFO20':'BIOFUEL',
        'Bio-HSFO80':'BIOFUEL',
        'Bio-HSFO180':'BIOFUEL',
        'Bio-HSFO380':'BIOFUEL',
        'Bio-HSFO500':'BIOFUEL',
        'ULSMGO':'GAS_OIL',
        'VLSMGO':'GAS_OIL',
        'MGO':'GAS_OIL',
        'ULSMDO (DMB)':'GAS_OIL',
        'VLSMDO (DMB)':'GAS_OIL',
        'MDO (DMB)':'GAS_OIL',
        'ULSFO20':'FUEL_OIL',
        'ULSFO180':'FUEL_OIL',
        'ULSFO380':'FUEL_OIL',
        'ULSFO500':'FUEL_OIL',
        'VLSFO20':'FUEL_OIL',
        'VLSFO180':'FUEL_OIL',
        'VLSFO380':'FUEL_OIL',
        'VLSFO500':'FUEL_OIL',
        'HSFO180':'FUEL_OIL',
        'HSFO380':'FUEL_OIL',
        'HSFO500':'FUEL_OIL',
        'HSFO700':'FUEL_OIL',
        'MMA':'METHANOL',
        'MMB':'METHANOL',
        'MMC':'METHANOL',
        'MM Others':'METHANOL',
      }
      for (const key in map) {
        const data={
          fuelTypeCode: key,
          oilType:map[key],
        }
        await this.$request.post({
          url:'/mpa/bunker_fuel_types/oil_type',
          data
        })
      }

    },
    handleEdit (value, line) {
      const url ='/mpa/bunker_fuel_types/oil_type'
      const data={
        fuelTypeCode: line.fuelTypeCode,
        oilType: value,
      }
      this.$request.post({
        url,
        data
      }).then((res) => {
        if (res?.code === 1000) {
          this.$store.dispatch("getMpaFuelTypeList");
          this.$message.success(this.$t("saveSuccess"));
        }
      }).catch((err) => {
        this.$message.error(err);
      });
    },

    handleClearCacheClick () {
      this.$request.delete(this.$apis.mpaBunkerFuelTypes).then(res => {
        if (res?.code === 1000) {
          this.$message.success('MPA product cache cleared')
        }
      })
      this.$store.dispatch("getMpaFuelTypeList");
    }
  }
}
</script>
<style lang="scss" scoped>
.manager-container {
  height: 100vh;
  width: 100%;
  .table-block {
    height: calc(100% - 200px);
  }
  .button-bar {
    padding: 8px 0;
    float: right;
  }
}
</style>
